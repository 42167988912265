import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  ListItem,
  List,
  Link,
  IconButton,
} from "@material-ui/core";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebookF, FaInstagram, FaMediumM } from "react-icons/fa";
import { FiSend, FiTwitter } from "react-icons/fi";
import Scroll from "react-scroll";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
// import { FaFacebookF } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
const ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  footerSection: {
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundImage: "url(images/footer.png)",
    backgroundPosition: "center",
    padding: "80px 0px 0",
    zIndex: "2",
    overflow: " hidden",
    "& .copy": {
      borderTop: "1px solid #fff",
      padding: "10px 0",
      textAlign: "center",
      fontWeight: 300,
      fontSize: "12px",
      color: "#626262",
    },

    "& ul": {
      paddingLeft: "0",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "0px",
      },
      "& li": {
        paddingLeft: "0",
        alignItems: "center",
        color: " #1D1D1D",
        fontSize: "14px",
        "& svg": {
          marginRight: "10px",
          color: "#408FAC",
          fontSize: "15px",
        },
      },
    },
    "& svg": {
      color: "#408FAC",
      fontSize: "15px",
    },
    "& p": {
      maxWidth: "272px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px !important",
        maxWidth: "100%",
      },
    },
    "& h6": {
      fontWeight: "800",
      fontSize: "15px",
      textTransform: "uppercase",
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "20px",
        fontSize: "14px !important",
      },
    },
    "& a": {
      color: "#fff",
      display: "flex",
      fontSize: "14px",
      alignItems: "center",
      fontWeight: "300",
      fontFamily: "'Inter', sans-serif",
      textDecoration: "none",
      "&:hover": {
        color: "#9b05a8",
        textDecoration: "none",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
  },
  socialIcons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& button": {
      borderRadius: "50%",
      width: "30px",
      height: "30px",
      display: "flex",
      border: "1px solid #fff",
      alignItems: "center",
      borderRadius: "50%",
      justifyContent: "center",
      "& svg": {
        color: "#fff",
      },
      "&:hover": {
        border: "1px solid #9b05a8 !important",
        color: "#9b05a8",
        "& svg": {
          color: "#9b05a8",
        },
      },
    },
  },
  supportBox: {
    display: "flex",
    justifyContent: "right",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "left",
    },
  },
  orderSet1: {
    [theme.breakpoints.down("xs")]: {
      order: 1,
    },
  },
  orderSet2: {
    [theme.breakpoints.down("xs")]: {
      order: 5,
    },
  },
  orderSet3: {
    [theme.breakpoints.down("xs")]: {
      order: 2,
    },
  },
  orderSet4: {
    [theme.breakpoints.down("xs")]: {
      order: 3,
    },
  },
  orderSet5: {
    [theme.breakpoints.down("xs")]: {
      order: 4,
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Box className={classes.footerSection}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              className={classes.orderSet1}
            >
              <Box>
                <Box mb={2}>
                  <RouterLink to="/">
                    <img src="images/Logo.png" style={{ maxWidth: "150px" }} />{" "}
                    <br />
                  </RouterLink>
                </Box>
                <Typography variant="body2">
                  Bitechchain believes in connecting the world with the power of
                  the blockchain Internet of Things.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={3}
              className={classes.orderSet2}
            >
              <Box>
                <Typography variant="h6">Social Accounts</Typography>
                <Box mt={2} ml={-2} display="flex" alignItems="center">
                  <Box className={classes.socialIcons} ml={2}>
                    <IconButton
                      onClick={() => window.open("https://web.whatsapp.com/")}
                    >
                      <AiOutlineWhatsApp />
                    </IconButton>
                  </Box>
                  &nbsp; &nbsp;
                  <Box className={classes.socialIcons}>
                    <IconButton
                      onClick={() => window.open("https://twitter.com/")}
                    >
                      <AiOutlineTwitter />
                    </IconButton>
                  </Box>
                  &nbsp; &nbsp;
                  <Box className={classes.socialIcons}>
                    <IconButton
                      onClick={() => window.open("https://www.facebook.com/")}
                    >
                      <FaFacebookF />
                    </IconButton>
                  </Box>
                  &nbsp; &nbsp;
                  <Box className={classes.socialIcons}>
                    <IconButton
                      onClick={() => window.open("https://www.instagram.com/")}
                    >
                      <AiOutlineInstagram />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={6}
              sm={4}
              md={2}
              lg={2}
              className={classes.orderSet3}
            >
              <Box>
                <Typography variant="h6">Product</Typography>
                <List>
                  <ListItem to="/blockchain" component={RouterLink}>
                    Blockchain
                  </ListItem>
                  <ListItem to="/" component={RouterLink}>
                    Dex
                  </ListItem>
                  <ListItem to="/social-network" component={RouterLink}>
                    Social Network
                  </ListItem>
                  <ListItem to="/gaming" component={RouterLink}>
                    Gaming
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={2}
              lg={2}
              className={classes.orderSet4}
            >
              <Box className="about">
                <Typography variant="h6">About Bitechchain</Typography>
                <List>
                  <ListItem to="/" component={RouterLink}>
                    Home
                  </ListItem>
                  <ListItem to="/about" component={RouterLink}>
                    About Us
                  </ListItem>
                  <ListItem to="/" component={RouterLink}>
                    Roadmap
                  </ListItem>
                  <ListItem to="/contactUs" component={RouterLink}>
                    Contact Us
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={2}
              lg={2}
              className={classes.orderSet4}
            >
              <Box className={classes.supportBox}>
                <Box>
                  <Typography variant="h6">Support</Typography>
                  <List>
                    <ListItem to="/faq" component={RouterLink}>
                      FAQs
                    </ListItem>
                    <ListItem to="/term-and-condition" component={RouterLink}>
                      Terms & Conditions
                    </ListItem>
                    <ListItem to="/privacy" component={RouterLink}>
                      Privacy policy
                    </ListItem>
                    <ListItem to="/exchange" component={RouterLink}>
                      Exchange
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className="copy" mt={4} align="center">
            <Typography
              variant="body2"
              style={{
                textAlign: "center",
                width: "100%",
                maxWidth: "100%",
                marginTop: "15px",
                marginBottom: "15px",
              }}
            >
              2022 © Bitechchain Group Limited Company. All Rights Reserved
              <br /> Lic. No. : 3851783561
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
}
