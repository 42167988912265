/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Typography,
  Button,
} from "@material-ui/core";
import { RiDashboard2Fill } from "react-icons/ri";
import SettingsIcon from "@material-ui/icons/Settings";
import { useHistory } from "react-router-dom";
import NavItem from "./NavItem";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import PostAddIcon from "@material-ui/icons/PostAdd";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: RiDashboard2Fill,
        href: "/dashboard",
      },
      {
        title: "Packages",
        icon: PostAddIcon,
        href: "/package",
      },
      {
        title: "My Network",
        icon: RssFeedIcon,
        href: "my-network",
      },
      {
        title: "Support",
        icon: SupervisorAccountIcon,
        href: "/support",
      },
      {
        title: "Finance",
        icon: CreditCardIcon,
        href: "/finance",
      },
      {
        title: "Profile",
        icon: PersonOutlineIcon,
        href: "/profile",
      },
      {
        title: "KYC",
        icon: SettingsIcon,
        href: "/kyc",
      },
      {
        title: "Logout",
        icon: ExitToAppIcon,
        href: "/login",
      },
    ],
  },
];
const sections1 = [
  {
    items: [
      {
        title: "Dashboard",
        icon: RiDashboard2Fill,
        href: "/dashboard",
      },
      {
        title: "Packages",
        icon: PostAddIcon,
        href: "/package",
      },
      {
        title: "My Network",
        icon: RssFeedIcon,
        href: "my-network",
      },
      {
        title: "Support",
        icon: SupervisorAccountIcon,
        href: "/support",
      },
      {
        title: "Finance",
        icon: CreditCardIcon,
        href: "/finance",
      },
      {
        title: "Profile",
        icon: PersonOutlineIcon,
        href: "/profile",
      },
      {
        title: "Logout",
        icon: ExitToAppIcon,
        href: "/login",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean()}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 200,
    top: 80,

    height: "calc(100% - 80px)",
    background: "#0F212E",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  logo: {
    width: "40px",
    paddingLeft: "20px",
    paddingTop: "21px",
    cursor: "pointer",
  },
  BITBox: {
    background: "#242B66",
    borderRadius: "2px",
  },
  mainBox: {
    padding: "20px",
  },
  Wallet_class: {
    width: "40px",
    height: "40px",
  },
  box1: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
  },
  yourWallet: {
    fontSize: "8px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "10px",
    marginLeft: "8px",
  },
  ZeroContent: {
    color: "white",
    fontSize: "22px",
    marginTop: "-12px",
    textAlign: "center",
    paddingBottom: "10px",
    fontFamily: "'Inter'",
    "@media(max-width:1280px)": {
      textAlign: "left",
      marginLeft: "56px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile, auth }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [sideBarData, setSideBarData] = useState([]);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  useEffect(() => {
    if (auth?.userData) {
      const filterFun = auth?.userData?.kyc_status ? sections1 : sections;
      setSideBarData(filterFun);
    }
  }, [auth?.userData]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box height="100%" display="flex" flexDirection="column">
        <Box height="100%" display="flex" flexDirection="column">
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <Box py={4}>
              <Box className={classes.mainBox}>
                <Box className={classes.BITBox}>
                  <Box className={classes.box1}>
                    <img
                      className={classes.Wallet_class}
                      src="/images/Wallet_icons.png"
                    ></img>
                    <Typography className={classes.yourWallet}>
                      Your Wallet Balance
                    </Typography>
                  </Box>
                  <Typography className={classes.ZeroContent} variant="h4">
                    {auth &&
                      auth.userWalletBalance &&
                      auth.userWalletBalance.balance &&
                      auth.userWalletBalance.balance.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
              {sideBarData &&
                sideBarData.map((section, i) => (
                  <List
                    key={`menu${i}`}
                    subheader={
                      <ListSubheader disableGutters disableSticky>
                        {section.subheader}
                      </ListSubheader>
                    }
                  >
                    {renderNavItems({
                      items: section.items,
                      pathname: location.pathname,
                    })}
                  </List>
                ))}
            </Box>
          </PerfectScrollbar>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <img
            src="./images/DashBoardLogo.png"
            alt="image"
            className={classes.logo}
            onClick={() => history.push("/")}
            style={{ cursor: "pointer" }}
          />
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
