import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  IconButton,
  Badge,
  Typography,
  Avatar,
  Tooltip,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { BsExclamationCircleFill } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.paper,
  },
  toolbar: {
    height: 80,
    backgroundColor: "hsla(0deg, 0%, 100%, 0) !important",
    backdropFilter: "blur(44px)",
    "& .MuiIconButton-root": {
      padding: "0px",
    },
  },
  namePlace: {
    "& .MuiDialog-container": {
      maxHeight: "78%",
      minWidth: "214",
    },
  },
  text: {
    fontSize: "15px",
    lineHeight: "0px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  text1: {
    fontSize: "13px",
    lineHeight: "0px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  margintypo: {
    marginTop: "-13px",
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData({ auth }) {
  const classes = useStyles();
  const history = useHistory();

  const userData = auth?.userData;
  return (
    <>
      <img
        src="images/DashBoardLogo.png"
        alt="sfsdg"
        style={{ cursor: "pointer" }}
        onClick={() => history.push("/")}
      />
      <Box flexGrow={1} />
      <Badge className={classes.namePlace} color="secondary"></Badge>
      <Box display="block" alignItems="center">
        <Typography variant="body1" className={classes.text}>
          <Typography className="trimtypo">
            {userData &&
              userData.first_name &&
              userData.last_name &&
              `${userData.first_name} ${userData.last_name}`}
          </Typography>
          {userData && userData.kyc_status ? (
            <Tooltip title="KYC Verified">
              <img
                src="/images/content/tick.png"
                alt=""
                style={{
                  width: "16px",
                  height: "16px",
                  marginLeft: "5px",
                  marginTop: " 3px",
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="KYC Pending">
              <BsExclamationCircleFill
                style={{
                  color: "orange",
                  cursor: "pointer",
                  fontSize: "14px",
                  marginLeft: "5px",
                  marginTop: "4px",
                }}
                onClick={() => history.push("/kyc")}
              />
            </Tooltip>
          )}
        </Typography>
        <br />
        <Box className={classes.margintypo}>
          <Typography
            variant="body1"
            className={classes.text1}
            style={{
              color: "#c9c9c9",
            }}
          >
            {userData && userData.email}
          </Typography>
        </Box>
      </Box>
      &nbsp;&nbsp;
      <IconButton onClick={(e) => history.push("/profile")}>
        <Badge className={classes.namePlace} color="secondary">
          {userData?.profile_pic ? (
            <Avatar
              src={
                userData?.profile_pic
                  ? userData?.profile_pic
                  : "/images/Profile.png"
              }
              alt="image"
            />
          ) : (
            <Avatar name={userData.first_name} />
          )}
        </Badge>
      </IconButton>
    </>
  );
}
