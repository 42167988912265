import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import Footer from "src/layouts/HomeLayout/Footer";
import { AuthContext } from "src/context/Auth";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "background: "radial-gradient(95.29% 54.98% at 63.22% 132.9%, #BA00BB 0%, rgba(15, 23, 85, 0) 100%) , radial-gradient(63.04% 67.19% at 77.35% 61.77%, #BA00BB 0%, rgba(15, 23, 85, 0) 100%) , radial-gradient(59.39% 30.8% at 75.9% 19.72%, rgba(186, 0, 187, 0.42) 0%, #AC02B2 0.01%, #0F1755 100%)"",
    background:
      "radial-gradient(95.29% 54.98% at 63.22% 132.9%, #BA00BB 0%, rgba(15, 23, 85, 0) 100%) , radial-gradient(63.04% 67.19% at 77.35% 61.77%, #BA00BB 0%, rgba(15, 23, 85, 0) 100%) , radial-gradient(59.39% 30.8% at 75.9% 19.72%, rgba(186, 0, 187, 0.42) 0%, #AC02B2 0.01%, #0F1755 100%)",
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 80,
    minHeight: "calc(100vh - 80px)",
    backgroundColor: theme.palette.background.darkgrey,
    // backgroundColor: "#1A2C38",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 200,
      // paddingRight: " 30px",
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    background: theme.palette.primary.paper,
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    width: "100%",
    position: "relative",
    WebkitOverflowScrolling: "touch",
    background: "rgba(255, 255, 255, 0.07)",
    borderRadius: "10px",
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const auth = useContext(AuthContext);
  return (
    <>
      <div className={classes.root}>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} auth={auth} />
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
          auth={auth}
        />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content} id="main-scroll">
              {children}
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
