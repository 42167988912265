import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiTab: {
      wrapper: {
        fontFamily: "'Inter'",
      },
    },
    // MuiTab: {
    //   root: {
    //     width: "1% !important",
    //   },
    // },
    MuiAvatar: {
      root: {
        borderRadius: "10px",
      },
    },
    MuiListItemIcon: {
      root: {
        width: "30px !important",
      },
    },
    MuiStep: {
      horizontal: {
        paddingLeft: "0",
        paddingRight: "0",
        marginLeft: "-22px",
      },
    },
    MuiStepLabel: {
      iconContainer: {
        display: "flex",
        flexShrink: "0",
        paddingRight: "1px",
        paddingLeft: "1px",
        justifyContent: "center",
      },
      label: {
        color: "transparent !important",
        fontSize: "15px",
        fontWeight: "600",
        fontFamily: "'Orbitron'",
        marginTop: "10px",
      },
    },
    MuiStepConnector: {
      lineHorizontal: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
      },
      line: {
        display: "block",
        marginTop: "-2px",
        marginLeft: "-31px",
        borderColor: "#5B4AB0",
      },
    },
    MuiAccordion: {
      root: {
        // border: "1px solid #D7D5D5",
        borderRadius: "10px",
      },
    },
    MuiInputBase: {
      root: {
        color: "#fff",
        cursor: "text",
        display: "inline-flex",
        position: "relative",
        fontSize: "12px",
        boxSizing: "border-box",
        alignItems: "center",
        fontFamily: "'Nunito', sans-serif",
        fontWeight: 400,
        lineHeight: "1.1876em",
        backgroundColor: "#0F0F0F",
      },
    },
    MuiIconButton: {
      edgeEnd: {
        zIndex: "1 !important",
      },
      root: {
        color: "#fff !important",
        padding: "0px",
        "&:hover": {
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },

    MuiOutlinedInput: {
      root: {
        minHeight: "55px",
        borderRadius: "30px",
      },
      input: {
        zIndex: "9",
        color: "#fff",
        height: "auto",
        padding: "18.5px 21px",
      },
      notchedOutline: {
        border: "2px solid #0000",
        boxShadow: "0px 0px 11px #c75cd3",
        background:
          "linear-gradient(96deg, #8b069e,#d06fd9) padding-box, linear-gradient(rgba(149, 219, 58, 0.7),rgba(233, 70, 255, 1)) border-box",
      },
      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "#222",
          borderColor: "#222",
        },
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        marginLeft: "8px",
        marginTop: "5px !important",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "transparent !important",
        "@media(max-width:1280px)": {
          backdropFilter: "blur(44px)",
          backgroundColor: "hsla(0deg, 0%, 100%, 0) !important",
        },
        "@media(max-width:767px)": {
          backdropFilter: "blur(44px)",
          backgroundColor: "hsla(0deg, 0%, 100%, 0) !important",
        },
      },
      outlined: {
        padding: "20px",
        width: "100%",
      },
      elevation4: {
        boxShadow: "none",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#fff" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
      },

      paper: {
        overflowY: "unset",
      },
      paperWidthSm: {
        maxWidth: "900px !important",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
        color: "#222",
        height: "0.1876em",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiButton: {
      containedSecondary: {
        fontFamily: "'Inter', sans-serif",
        background: "linear-gradient(90deg, #E946FF -16.33%, #E6D292 151.68%)",
        borderRadius: "100px",
        color: "#fff",
        fontSize: "10px",
        border: "1.5px solid #e782d0",
        padding: "5px 19px",
        "&:hover": {
          color: "#ffffff",
          border: "solid 1.5px transparent",
          boxShadow: "2px 1000px 1px #570d80 inset",
          background:
            "linear-gradient(286deg, rgba(138,18,241,1) 0%, rgba(226,223,19,1) 65%, rgba(241,113,26,1) 70%, rgba(218,48,149,1) 96%)",
          backgroundOrigin: "border-box",
        },
      },

      containedPrimary: {
        height: "41px",
        border: "1.5px solid #6e3fa6",
        background:
          "linear-gradient(182.4deg, #611BB5 2.54%, rgba(255, 255, 255, 0) 222.36%)",
        filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
        color: "#fff",
        borderRadius: "50px",
        whiteSpace: "pre",
        fontSize: "12px",
        height: "45px",
        lineHeight: " 21px",
        padding: "10px 35px",
        fontWeight: "500",

        "&:hover": {
          color: "#ffffff",
          border: "solid 1.5px transparent",
          boxShadow: "2px 1000px 1px #570d80 inset",
          background:
            "linear-gradient(286deg, rgba(138,18,241,1) 0%, rgba(226,223,19,1) 65%, rgba(241,113,26,1) 70%, rgba(218,48,149,1) 96%)",
          backgroundOrigin: "border-box",
        },
      },
      contained: {
        borderRadius: "50px",
        color: "#ffffff",
        fontWeight: 600,
        padding: "5px 19px",
      },
      containedSizeLarge: {
        height: "55px",
      },
      outlinedPrimary: {
        color: "#ffffff",
        border: "solid 1.5px transparent",
        height: "45px",
        padding: "7px 35px",
        fontSize: "12px",
        boxShadow: "2px 1000px 1px #36126c inset",
        fontWeight: "500",
        whiteSpace: "pre",
        borderRadius: "50px",
        background:
          "linear-gradient(286deg, rgba(138,18,241,1) 0%, rgba(226,223,19,1) 65%, rgba(241,113,26,1) 70%, rgba(218,48,149,1) 96%)",
        backgroundOrigin: "border-box",
        "@media(max-width:767px)": {
          padding: "7px 40px",
        },
        "@media(max-width:599.9px)": {
          padding: "7px 25px",
        },
        "&:hover": {
          border: "1.5px solid #611BB5",
          background:
            "linear-gradient(182.4deg, #611BB5 2.54%, rgba(255, 255, 255, 0) 222.36%)",
          filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
          color: "#fff",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },

    MuiTypography: {
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
    MuiDropzoneArea: {
      root: {
        background: "rgba(0, 0, 0, 0.1) !important",
      },
      text: {
        fontSize: "20px",
        color: "#939090",
      },
    },
    MuiTableCell: {
      root: {
        border: "none !important",
        padding: "13px",
      },
    },
    MuiPaginationItem: {
      outlined: {
        color: "#fff",
        border: "none",
      },
      page: {
        "&.Mui-selected": {
          background: "linear-gradient(90deg, #DE1EE9 15.4%, #FF9268 68.73%)",
        },
      },
      root: {
        background: "rgba(255, 255, 255, 0.1)",
        // "&.Mui-disabled": {
        //   background: "linear-gradient(90deg, #DE1EE9 15.4%, #FF9268 68.73%)",
        // },
      },
    },
    MuiDropzoneArea: {
      root: {
        background: "rgba(0, 0, 0, 0.1) !important",
      },
      icon: {
        color: "#4b186a",
        opacity: 0,
      },
      text: {
        color: "#939090",
        marginTop: "50px !important",
      },
    },
    MuiTabs: {
      scroller: {
        overflow: "auto !important",
      },
    },

    MuiInputAdornment: {
      positionEnd: {
        marginLeft: "0px",
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Inter', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      // dark: "#de0d0d",
      // light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#0F0F0F",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
