import React from "react";

const Logo = (props) => {
  return (
    <img
      src="/images/Logo.png"
      style={{ width: "43px", marginTop: "10px" }}
      alt="Logo"
      {...props}
    />
  );
};

export default Logo;
