import React from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      "radial-gradient(54.11% 26.86% at 73.99% 20.4%, rgba(186, 0, 187, 0.42) 0%, #AC02B2 0.01%, #0F1755 100%)",
  },
  MainLayout: {
    minHeight: "calc(100vh - 415px)",
    overflowX: "hidden",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <TopBar />
      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>

      <div className={classes.MainLayout}>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
